<template>
  <b-card>
    <b-row class="justify-content-between">
      <b-col class="pr-md-32 pr-md-120">
        <h4>Disabled state</h4>

        <p class="hp-p1-body">
          Set the <code>disabled</code> prop to disable button default
          functionality. <code>disabled</code> also works with buttons rendered
          as <code>&lt;a&gt;</code> elements and
          <code>&lt;router-link&gt;</code> (i.e. with the <code>href</code> or
          <code>to</code> prop set).
        </p>
      </b-col>

      <b-col class="hp-flex-none w-auto">
        <b-button
          @click="codeClick()"
          variant="text"
          class="btn-icon-only show-code-btn"
        >
          <i
            class="ri-code-s-slash-line hp-text-color-black-80 hp-text-color-dark-30 lh-1"
            style="font-size: 16px"
          ></i>
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <div class="col-12 mt-16">
        <b-button disabled class="mr-8 mb-8">Primary Button</b-button>
        <b-button disabled variant="primary" class="mr-8 mb-8">
          <i class="ri-arrow-right-s-line remix-icon"></i>
          <span>Primary Button</span>
        </b-button>
        <b-button disabled variant="text" class="mr-8 mb-8">
          <span>Text Button</span>
        </b-button>
      </div>

      <div
        v-if="codeActive"
        class="col-12 mt-24 hljs-container"
        :class="{ active: codeActiveClass }"
      >
        <pre v-highlightjs>
          <code class="hljs html">
            {{ codeText }}
          </code>
        </pre>
      </div>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BButton } from "bootstrap-vue";

import code from "./code";

export default {
  data() {
    return {
      codeText: code.disabled,
      codeActive: false,
      codeActiveClass: false,
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
  },
  methods: {
    codeClick() {
      this.codeActive = !this.codeActive;

      setTimeout(() => {
        this.codeActiveClass = !this.codeActiveClass;
      }, 100);
    },
  },
};
</script>
