var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'General',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" Use Bootstrap's custom "),_c('code',[_vm._v("b-button")]),_vm._v(" component for actions in forms, dialogs, and more. Includes support for a handful of contextual variations, sizes, states, and more. ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('basic')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('type')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('sizing')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('solid-color')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('outline-color')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('dashed-color')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('ghost-buttons')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('text-buttons')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('link-buttons')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('pill')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('squared')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('disabled')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('pressed-toggling')],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12","lg":"6"}},[_c('icon-buttons')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12","lg":"6"}},[_c('icon-only')],1)],1)],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('gradient')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }